import React, { useEffect, useState } from 'react';
import { FaMedal } from 'react-icons/fa';
import './styles.css';

function Eventos() {
  const [data, setData] = useState({});
  const [selectedDate, setSelectedDate] = useState('mar-2025'); // Data padrão

  const availableDates = [
    'out-2023', 
    'dez-2023', 
    'mar-2024', 
    'mai-2024', 
    'jul-2024', 
    'nov-2024', 
    'set-2024',
    'mar-2025' 
  ];

  const getData = async (date) => {
    await fetch(`./data-${date}.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    )
    .then(response => response.json())
    .then(data => {
      data.categorias.map(item => {
        if (item.ordem === "asc") {
          item.pontuacoes.sort((a, b) => a.pts - b.pts);
        } else if (item.ordem === "desc") {
          item.pontuacoes.sort((a, b) => b.pts - a.pts);
        }
      });
      setData(data);
    })
    .catch(error => console.error(error));
  }

  const get_medal = (pos) => {
    if (pos <= 3) return "medal-icon" + pos;
    return "medal-iconx";
  }

  useEffect(() => {
    document.title = 'CCTMontana - Eventos';
    getData(selectedDate);
  }, [selectedDate]);

  return (
    <div className='evento-container'>
      <div className='buttons-container' style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px', flexWrap: 'wrap' }}>
        {availableDates.map(date => (
          <button 
            key={date} 
            className={`date-button ${selectedDate === date ? 'active' : ''}`} 
            onClick={() => setSelectedDate(date)}
          >
            {date}
          </button>
        ))}
      </div>
      
      {(Object.keys(data).length === 0) || (!data.show) ? (
        <h2>Não há eventos</h2>
      ) : (
        <div className='ranking'>
          <div>
            <h2 className='estilo2' style={{margin: '15px 0px'}}>{data.desc}</h2>
            <h3 className='estilo3'>Data: {data.data}</h3>
            <h3 className='estilo3' style={{marginBottom: '15px' }}>Colocações:</h3>
          </div>
          {
            data.categorias.map((item, idx) => (
              <div key={idx}>
                <h6>{item.desc}</h6>
                {item.pontuacoes.map((p, index) => (
                  <div key={index} className='item'>
                    <div style={{minWidth:'80%', paddingRight: '5px'}}>
                      {index < data.premiacao_ate && (
                        <span className={get_medal(index+1)}>
                          <FaMedal />
                        </span>
                      )}
                      {index+1}° {p.nome} 
                    </div>
                    <div>
                      <span className='estilo-pts'>Pontuação:</span> {p.pts.toFixed(3)}
                    </div>
                  </div>
                ))}
                <br />
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
}

export default Eventos;