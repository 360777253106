import React, { useEffect } from 'react';

import banner_img from '../../assets/fundo_banner.jpg';
import logo_black_p from '../../assets/logo_cct_black_p.png';
import './styles.css';

import carrossel_img1 from '../../assets/cctm1.jpeg';
import carrossel_img2 from '../../assets/cctm2.jpeg';
import carrossel_img3 from '../../assets/cctm3.jpeg';
import carrossel_img4 from '../../assets/cctm4.jpeg';
import carrossel_img5 from '../../assets/cctm5.jpeg';
import carrossel_img6 from '../../assets/cctm6.jpeg';
import carrossel_img7 from '../../assets/cctm7.jpeg';
import carrossel_img8 from '../../assets/cctm8.jpeg';

const carrosselImages = [carrossel_img1, 
  carrossel_img2,
  carrossel_img3, 
  carrossel_img4,
  carrossel_img5,
  carrossel_img6,
  carrossel_img7,
  carrossel_img8,
];

function Main() {
  useEffect(() => {
    document.title = 'CCTMontana';
  }, []);

  return (
    <div className='main-container'>
      <div className='banner' style={{ backgroundImage: `url(${banner_img})` }}>
        <img className='logo' src={logo_black_p} alt='Logo' />
      </div>
      
      <div className='content'>
        <div className='info-container'>
          <div className='text-section'>
            <h2>Bem-vindo ao CCT Montana</h2>
            <p>
              Montana, o destino perfeito para entusiastas de tiro e amantes da natureza. 
              Localizado em Itaicoca, nosso clube, fundado em 2020, combina adrenalina e serenidade, oferecendo pistas de tiro iluminadas, 
              áreas de camping e espaços de pesca. 
              Promovemos segurança, responsabilidade e educação no manuseio de armas de fogo, organizando eventos e competições que 
              fortalecem nossa comunidade unida. Se você compartilha nossa paixão pelo tiro e pela vida ao ar livre, junte-se a nós no CCT Montana 
              e aproveite uma experiência única e gratificante.
            </p>
          </div>
          
          <div className='image-section'>
            <img className='carousel-image' src={carrosselImages[6]} alt='CCT Montana' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main;
